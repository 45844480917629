import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { FixtureStatus } from './FixtureStatus';
import { FixtureDetails } from '../fixture-details/FixtureDetails';
import { FixtureWatermarks } from '../fixture-details/FixtureWatermarks';
import { FixtureMedia } from '../fixture-details/fixture-media/FixtureMedia';
import { FixtureLinks } from '../fixture-details/fixture-links/FixtureLinks';
import { ResponsiveFixtureLinks } from '../fixture-details/fixture-links/ResponsiveFixtureLinks';
import { Broadcasters } from './broadcasters/Broadcasters';
import { OtherMatches } from '../other-matches/OtherMatches';

import { useAutoPollingMatchItem } from 'store/modules/api/hooks/useAutoPollingMatchItem';
import { navSelector } from 'store/modules/competition-nav/selectors';

const matchStatuses = PULSE.app.common.CONSTANTS.MATCH_STATUS;

const setFetchLiveMatch = (match) => {
    const matchInfoStatus = PULSE.app.common.match.getMatchStatus(match.status);

    if (
        matchInfoStatus === matchStatuses.LIVE ||
        PULSE.app.common.match.startsWithinAnHour(
            matchInfoStatus,
            match.utcStartTime
        ) ||
        (PULSE.app.common.match.isPastStartTime(match.utcStartTime) &&
            matchInfoStatus !== matchStatuses.COMPLETED)
    ) {
        return true;
    }
};

export const Fixture = ({
    match,
    mcLink,
    reportLabel,
    broadcastGuideUrl,
    roundNumber,
    inView,
    firstLiveOrUpcomingFixture,
    scrollToRound,
    setScrollToRound,
    index
}) => {
    const scrollToRef = useRef([]);
    const nav = useSelector(navSelector);

    useEffect(() => {
        if (
            match.id === firstLiveOrUpcomingFixture &&
            scrollToRef.current[match.id] &&
            scrollToRef.current[match.id].scrollIntoView() &&
            !scrollToRound
        ) {
            scrollToRef.current.scrollIntoView();
            setScrollToRound(true);
        }
    }, []);

    const { data: liveMatch } = useAutoPollingMatchItem(match.providerId, {
        skip: !setFetchLiveMatch(match)
    });

    // if match is live or polling before starting
    // we should use the status from the match data
    const matchStatus = PULSE.app.common.match.getMatchStatus(
        liveMatch?.match.status ? liveMatch.match.status : match.status
    );

    const fixtureData = {
        ...match,
        mcLink,
        matchLink: PULSE.app.common.url.getMatchURL(match.id),
        metadata: match.metadata || {},
        compseasonProviderId: match.compSeason.providerId
    };

    return (
        <>
            <span
                className="fixtures__scroll-to-ref"
                ref={(ref) => (scrollToRef.current[match.id] = ref)}
            ></span>

            <div
                className={`fixtures__item fixtures__item--${matchStatus}`}
                data-match-id={match.id}
                data-match-provider-id={match.providerId}
                data-match-status={matchStatus}
                data-round-id={match.round.id}
            >
                {matchStatus ===
                    PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE && (
                    <FixtureWatermarks
                        home={match?.home?.team}
                        away={match?.away?.team}
                    />
                )}

                <div className="fixtures__content">
                    <FixtureStatus
                        match={match}
                        status={matchStatus}
                        liveMatch={liveMatch}
                    />

                    <div className="fixtures__media-container">
                        {matchStatus ===
                            PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING ||
                        matchStatus ===
                            PULSE.app.common.CONSTANTS.MATCH_STATUS
                                .COMPLETED ? (
                            <FixtureMedia
                                match={match}
                                matchStatus={matchStatus}
                                inView={inView}
                            />
                        ) : null}
                    </div>

                    <FixtureDetails
                        match={match}
                        liveMatch={liveMatch}
                        link={fixtureData.matchLink}
                        matchStatus={matchStatus}
                        index={index}
                    />

                    <div className="fixtures__link-container">
                        <FixtureLinks
                            match={match}
                            matchStatus={matchStatus}
                            metadata={fixtureData.metadata}
                            reportLabel={reportLabel}
                            broadcastGuideUrl={broadcastGuideUrl}
                            index={index + 1}
                        />
                    </div>

                    {(matchStatus ===
                        PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING ||
                        matchStatus ===
                            PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE) && (
                        <Broadcasters
                            matchId={match.id}
                            roundNumber={roundNumber}
                            inView={inView}
                        />
                    )}

                    <ResponsiveFixtureLinks
                        match={match}
                        matchStatus={matchStatus}
                        metadata={fixtureData.metadata}
                        reportLabel={reportLabel}
                        broadcastGuideUrl={broadcastGuideUrl}
                        inView={inView}
                    />
                </div>
            </div>

            {nav?.otherMatches.isActive ? (
                <OtherMatches
                    roundNumber={roundNumber}
                    inView={inView}
                    parentMatchId={match.id}
                />
            ) : (
                ''
            )}
        </>
    );
};

Fixture.propTypes = {
    match: PropTypes.object.isRequired,
    mcLink: PropTypes.bool,
    reportLabel: PropTypes.string,
    broadcastGuideUrl: PropTypes.string,
    roundNumber: PropTypes.number,
    inView: PropTypes.bool,
    firstLiveOrUpcomingFixture: PropTypes.number,
    scrollToRound: PropTypes.bool,
    setScrollToRound: PropTypes.func,
    index: PropTypes.number
};
